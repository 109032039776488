import React from 'react';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}


class AuditInquiryObjects extends React.Component {
    constructor(props) {
        super(props);
        this.allowUpdating = this.allowUpdating.bind(this);
        this.setCellValue = this.setCellValue.bind(this);
    }
    isClosed(status) {
        return  status && status > 2;
    }

    allowUpdating(e) {
        const { ai_status } = this.props.data.data;
        return !this.isClosed(ai_status);
    }
    setCellValue(newData, value, currentRowData) {
        newData.aio_permission = (value === true ? 1 : 0);
    }
    render() {
        const { ai_id } = this.props.data.data;
        const store = new CustomStore({
            key: 'aio_id',
            load() {
                return fetch('https://dgs.it-tn.de/api/getAuditInquiryObjects.php?inquiryId=' + ai_id)
                    .then((response) => response.json())
                    .catch(() => { throw new Error('Data Loading Error');});
            },
            update: (key, values) =>  {
                return fetch(`https://dgs.it-tn.de/api/AuditInquiryObjects/service.php?ID=${key}` , {
                    method: "OPTIONS",
                    body:  JSON.stringify(values)
                })
                .then(handleErrors);
            },
            remove: (key) => {
                 return fetch(`https://dgs.it-tn.de/api/AuditInquiryObjects/service.php?ID=${key}`, {
                    method: "DELETE"
                })
                .then(handleErrors);
            }
    });
        return (
            <><React.Fragment>
                <DataGrid
                    dataSource={store}
                    showBorders={true}
                    columnAutoWidth={false}
                    columnHidingEnabled={true}
                    hoverStateEnabled={true}
                    focusedRowEnabled={true}
                    defaultFocusedRowIndex={0}
                    allowColumnResizing={true}
                    //onRowValidating={this.onRowValidating}
                    remoteOperations={false}
                >
                    <Paging defaultPageSize={15} />
                    <Pager showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        //infoText={'Seite {0} von {1} ({2} Zeilen)'}
                    />
                    <FilterRow visible={true} />
                    <Editing
                        mode="row"
                        allowUpdating={this.allowUpdating}
                    />
                    <Column
                        dataField={'rowId'}
                        allowEditing={false}
                        allowFiltering={false}
                        width={90}
                        caption={'#'}
                        hidingPriority={0} />
                    <Column
                        dataField={'company'}
                        dataType={"string"}
                        allowEditing={false}
                        caption={'Standort'}
                        hidingPriority={4}> 
                    </Column>
                    <Column
                        dataField={'auditJobs'}
                        allowEditing={false}
                        caption={'Audits'}
                        hidingPriority={1}> 
                    </Column>
                    <Column
                        dataField={'aio_permission'}
                        allowFiltering={false}
                        dataType={"boolean"}
                        allowEditing={true}
                        caption={'Freigabe'}
                        //setCellValue={this.setCellValue}
                        hidingPriority={3} />
                    <Column
                        dataField={'aio_remark'}
                        //dataType={"string"}
                        allowEditing={true}
                        allowFiltering={false}
                        caption={'Bemerkung'}
                        hidingPriority={2} />
                </DataGrid>
            </React.Fragment>
                <React.Fragment>
                    <p className={'content-block'} style={{ fontSize: 10 }}><strong>Legende</strong><br /> AS = Arbeitssicherheit<br />OF = Ortsfeste elektrische Anlagen<br />OV = Ortsver&auml;nderliche elektrische Betriebsmittel<br />SB = Sicherheitsbeleuchtung</p>
                </React.Fragment></>
        );
    }
}
export default AuditInquiryObjects;
