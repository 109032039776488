import React from 'react';
import DataGrid, {
    Column,
    MasterDetail,
    Pager,
    Paging,
    FilterRow
} from 'devextreme-react/data-grid';
import defaultUser from '../../utils/default-user';
import auditjobs from '../auditjobs/auditjobs.js';
//console.log(defaultUser.client)
//const jsonUrl = 'https://audit-pro.net/api/getAuditsDone.php?clientId=' + defaultUser.client;

class Auditdone extends React.Component {
    render() {
        const jsonUrl = 'https://dgs.it-tn.de/api/getAuditsDone.php?clientId=' + defaultUser.client;
        return (
            <><React.Fragment>
                <h2 className={'content-block'}>Audits durchgef&uuml;hrt</h2>
                <p className={'content-block'} style={{ fontSize: 12 }}><strong>Angezeigt sind die durchgef&uuml;hrten Audits der letzten 90 Tage.<br />
                    S&auml;mtliche durchgef&uuml;hrte Audits sind &uuml;ber Standorte/Allgemein zu jedem Standort verf&uuml;gbar.</strong></p>
                <DataGrid
                    dataSource={jsonUrl}
                    showBorders={true}
                    showColumnLines={true}
                    //defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    onCellPrepared={this.onCellPrepared}
                    noDataText={'Keine Daten'}
                >
                    <Paging defaultPageSize={15} />
                    <Pager showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        //infoText={'Seite {0} von {1} ({2} Zeilen)'}
                    />
                    <FilterRow visible={true} />
                    <Column
                        dataField={'rowId'}
                        width={90}
                        caption={'#'}
                        hidingPriority={1} />
                    <Column
                        dataField={'company'}
                        caption={'Standort'}
                        hidingPriority={7} />
                    <Column
                        dataField={'street'}
                        caption={'Stra\u00dfe'}
                        hidingPriority={4} />
                    <Column
                        dataField={'zipcode'}
                        caption={'PLZ'}
                        hidingPriority={2} />
                    <Column
                        dataField={'city'}
                        caption={'Ort'}
                        hidingPriority={3} />
                    <Column
                        dataField={'objectJobs'}
                        caption={'Audits'}
                        hidingPriority={6} />
                    <Column
                        dataField={'displayDate'}
                        caption={'Plandatum'}
                        hidingPriority={5} />
                    <Column
                        dataField={'dateDone'}
                        caption={'Auditdatum'}
                        hidingPriority={7} />
                    <MasterDetail
                        enabled={true}
                        component={auditjobs}
                    />
                </DataGrid>
            </React.Fragment>
                <React.Fragment>
                    <p className={'content-block'} style={{ fontSize: 10 }}><strong>Legende</strong><br /> AS = Arbeitssicherheit<br />OF = Ortsfeste elektrische Anlagen<br />OV = Ortsver&auml;nderliche elektrische Betriebsmittel<br />SB = Sicherheitsbeleuchtung</p>
                </React.Fragment></>
        );
    }
    onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField === "company") {
            const useable = e.data.useable;
            const clientDone = e.data.ocj_client_done;
            e.cellElement.style.color = clientDone < 0 ? "red" : "undefined"; 
        }
    }
}
export default Auditdone;
