import React from 'react';
import DataGrid, {
    Column,
    Editing
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const renderGridCell = (data) => {
    if (data.value) return <a target="_blank" rel="noopener" href={data.text}>Pr&uuml;fbericht</a> //Pr&uuml;fbericht
    else return <div>Bericht nicht verf&uuml;gbar</div>
};
class Auditjobs extends React.Component {
    constructor(props) {
        super(props);
        //this.allowUpdating = this.allowUpdating.bind(this);
        this.setCellValue = this.setCellValue.bind(this);
    }
    setCellValue(newData, value, currentRowData) {
        newData.ocj_client_done = (value === true ? 1 : 0);
    }
    render() {
        const { id } = this.props.data.data;
        //const jsonUrl = 'https://dgs.it-tn.de/api/getAuditJobs.php?auditId=' + id;
        const store = new CustomStore({
            key: 'id',
            load() {
                return fetch('https://dgs.it-tn.de/api/getAuditJobs.php?auditId=' + id)
                    .then((response) => response.json())
                    .catch(() => { throw new Error('Data Loading Error'); });
            },
            update: (key, values) => {
                //console.log(values);
                return fetch(`https://dgs.it-tn.de/api/AuditJobs/service.php?ID=${key}`, {
                    method: "OPTIONS",
                    body: JSON.stringify(values)
                })
                    .then(handleErrors);
            },
            remove: (key) => {
                return fetch(`https://dgs.it-tn.de/api/AuditJobs/service.php?ID=${key}`, {
                    method: "DELETE"
                })
                    .then(handleErrors);
            }
        });
        return (
            <React.Fragment>
                <DataGrid
                    dataSource={store}
                    showBorders={true}
                    showColumnLines={true}
                    columnAutoWidth={true}
                    focusedRowEnabled={true}
                    noDataText={'Keine Daten'}
                    allowColumnResizing={true}
                    remoteOperations={false}
                    onCellPrepared={this.onCellPrepared}
                >
                <Editing
                    mode="row"
                    allowUpdating={true}
                />
                <Column
                    dataField={'id'}
                    allowEditing={false}
                    width={90}
                    caption={'Id'}
                    hidingPriority={1} />
                <Column
                    dataField={'jobDescription'}
                    allowEditing={false}
                    caption={'Audit'}
                    hidingPriority={2} />
                <Column
                    dataField={'reportLink'}
                    allowEditing={false}
                    cellRender={renderGridCell}
                    caption={' '}
                    hidingPriority={3} />
                <Column
                    dataField={'nextDate'}
                    allowEditing={false}
                    caption={'N\u00e4chstes Audit'}
                    hidingPriority={2} />
                <Column
                    dataField={'ocj_client_done'}
                    allowFiltering={false}
                    dataType={'boolean'}
                    allowEditing={true}
                    caption={'Protokoll bearbeitet'}
                    //setCellValue={true}
                    hidingPriority={3} />
                <Column
                    dataField={'ocj_client_remark'}
                    //dataType={"string"}
                    allowEditing={true}
                    allowFiltering={false}
                    caption={'Bemerkung'}
                    hidingPriority={2} />
                </DataGrid>
            </React.Fragment>
        );
    }
    onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField === "jobDescription") {
            const useable = e.data.useable;
            const clientDone = e.data.ocj_client_done;
            e.cellElement.style.color = clientDone === 0  ? "red" : "undefined";
        }
    }
}
export default Auditjobs;
